import getSystemLanguage from "./getSystemLanguage";

interface Element {
  language: string;
  localText: string;
}

export default function getLocaleTranslate(elements: Element[]): string {
  const userLanguage = getSystemLanguage();

  const element = elements.find((el) => el.language === userLanguage);

  return element ? element.localText : "";
}
