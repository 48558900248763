import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import fetchApi from "../../../api/fetchApi";
import useDimensions from "../../../hooks/useDimensions";
import useRecentContractNumbers from "../../../hooks/useRecentContractNumbers";
import useUser from "../../../hooks/useUser";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { ProductPackage } from "../../../models/Product";
import { Service } from "../../../models/Service";
import Container from "../../layout/container";
import StepFormOne from "./stepFormOne";
import StepFormThree from "./stepFormThree";
import StepFormTwo from "./stepFormTwo";
import StepperNavigation from "./stepperNavigation";
import Loading from "../loading";

interface StepperProps {
  service: Service;
}

const Stepper = ({ service }: StepperProps) => {
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const { isSmall } = useDimensions();
  const [selectedPackage, setSelectedPackage] = useState<ProductPackage | null>(
    null
  );

  const [paymentMode, setPaymentMode] = useState<PaymentMethod | undefined>();
  const { user, updateUser, clearData } = useUser();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isInitiateCashout, setisInitiateCashout] = useState<boolean>(false);
  const { addContractNumber } = useRecentContractNumbers(
    service?.serviceid as string
  );
  const navigate = useNavigate();

  const handleReset = () => {
    setSelectedPackage(null);
    setStep(1);
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    }

    if (step === 2) {
      setStep(3);
    }

    if (step === 3) {
      setisLoading(true);
      fetchApi("quotestd")
        .post({
          payItemId: user?.paymentProvider,
          amount: Number(user?.amount),
        })
        .then((e) => {
          updateUser({ paymentProvider: e.payItemId });
          addContractNumber(user?.cardNumber);
          setisInitiateCashout(true);
          fetchApi("collectstd")
            .post({
              quoteId: e.quoteId,
              customerPhonenumber: user?.customerNumber,
              customerEmailaddress: "cedricngoumti@gmail.com",
              customerName: "WebPAY",
              customerAddress: "WebPAY",
              serviceNumber: user?.customerNumber,
              trid: uuidv4(),
            })
            .then((data) => {
              if (data.ptn) {
                updateUser({
                  tag: `${data.ptn} - ${user?.customerNumber}`,
                  serviceTitle: service?.title,
                });
                navigate(`/verify/${data.trid}`);
              }
            })
            .finally(() => setisInitiateCashout(false));
        })
        .finally(() => setisLoading(false));
    }

    return;
  };

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    }

    if (step === 3) {
      setStep(2);
    }

    return;
  };

  return (
    <Container bordered={!isSmall} sx={{ position: "relative" }}>
      {(isLoading || isInitiateCashout) && <Loading />}
      {step === 1 && (
        <StepFormOne
          service={service}
          handleNext={handleNext}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          //setAccountNumber={setAccountNumber}
        />
      )}
      {step === 2 && (
        <StepFormTwo
          service={service}
          handleGoBack={handleGoBack}
          handleNext={handleNext}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          handleReset={handleReset}
          paymentMode={paymentMode}
          setisLoading={setisLoading}
          isLoading={isLoading}
          setPaymentMode={setPaymentMode}
        />
      )}

      {step === 3 && (
        <StepFormThree service={service} selectedPackage={selectedPackage} />
      )}

      {step !== 1 && step !== 2 && (
        <StepperNavigation
          handleReset={handleReset}
          handleGoBack={handleGoBack}
          handleNext={handleNext}
          step={step}
        />
      )}
    </Container>
  );
};

export default Stepper;
