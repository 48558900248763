import { useParams } from "react-router-dom";
import { useQueryService } from "../../api/queries";
import Loading from "../../components/ui/loading";
import Stepper from "../../components/ui/stepper";
import StepperBill from "../../components/ui/stepperBill";
import {
  PRODUCT,
  SEARCHABLE_BILL,
  SUPPORTED_SERVICES,
  TOPUP,
} from "../../constants/constant";
import { Service } from "../../models/Service";
import NotFound from "../NotFound";

const ServicePage = () => {
  const { id } = useParams();
  const { data = [], isFetching } = useQueryService();
  const service = data?.find(
    (item: Service) =>
      (item?.serviceid === id ||
        item?.title.toLowerCase() === id?.toLowerCase()) &&
      SUPPORTED_SERVICES.includes(Number(item?.serviceid))
  );

  if (isFetching) return <Loading />;

  if (!isFetching && !service) return <NotFound />;

  return (
    <>
      {(service?.type === PRODUCT || service?.type === TOPUP) && (
        <Stepper service={service} />
      )}

      {service?.type === SEARCHABLE_BILL && <StepperBill service={service} />}
    </>
  );
};

export default ServicePage;
