import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Container from "../../components/layout/container";
import ServiceCard from "../../components/ui/serviceCard";
import { SUPPORTED_SERVICES } from "../../constants/constant";
import useDimensions from "../../hooks/useDimensions";
import { Service } from "../../models/Service";
import useQueryService from "../../api/queries/useQueryService";
import { useQueryCashout } from "../../api/queries";

const EmbedList = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [searchText, setSearchText] = useState(""); // Texte de recherche
  const { isSmall } = useDimensions();
  const { data = [] } = useQueryService();

  useQueryCashout();

  const getAvalaibleServices = data?.filter((service: Service) => {
    return SUPPORTED_SERVICES.includes(Number(service?.serviceid));
  });

  useEffect(() => {
    setServices(getAvalaibleServices);
  }, [data]);

  return (
    <Container justifyContent="center" alignItems="center">
      <Box
        display={isSmall ? "block" : "flex"}
        justifyContent={"flex-start"}
        flexWrap={"wrap"}
        mx={isSmall ? 0 : 10}
        width={"90%"}
        mt={5}
        data-testid="service-grid"
      >
        {searchText && filteredServices.length > 0 && (
          <>
            {filteredServices.map((service, key) => (
              <ServiceCard service={service} key={key} embedded />
            ))}
          </>
        )}

        {!searchText && (
          <>
            {services.map((service, key) => (
              <ServiceCard service={service} key={key} embedded />
            ))}
          </>
        )}
      </Box>
    </Container>
  );
};

export default EmbedList;
