import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import fetchApi from "../../../api/fetchApi";
import config from "../../../config";
import useDimensions from "../../../hooks/useDimensions";
import useRecentContractNumbers from "../../../hooks/useRecentContractNumbers";
import useUser from "../../../hooks/useUser";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { ProductPackage } from "../../../models/Product";
import { Service } from "../../../models/Service";
import Container from "../../layout/container";
import StepFormOne from "./stepFormOne";
import StepFormThree from "./stepFormThree";
import StepFormTwo from "./stepFormTwo";
import StepperNavigation from "./stepperNavigation";

interface StepperProps {
  service: Service;
}

const StepperBill = ({ service }: StepperProps) => {
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const { isSmall } = useDimensions();
  const [selectedPackage, setSelectedPackage] = useState<ProductPackage | null>(
    null
  );
  const [paymentMode, setPaymentMode] = useState<PaymentMethod | undefined>();
  const { user, updateUser, clearData, billSearch } = useUser();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isInitiateCashout, setisInitiateCashout] = useState<boolean>(false);
  const { totalAmount, processsingServiceCharge } = billSearch;
  const navigate = useNavigate();
  const location = useLocation();
  const isEmbeddedApp = location.pathname.includes("/embed");
  const { addContractNumber } = useRecentContractNumbers(
    service?.serviceid as string
  );

  useEffect(() => {
    clearData();
  }, []);

  const handleReset = () => {
    setSelectedPackage(null);
    navigate(`/service/${user?.billService?.serviceid}`);
    setStep(1);
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    }

    if (step === 2) {
      setStep(3);
    }

    if (step === 3) {
      setisLoading(true);
      fetchApi("quotestd")
        .post({
          payItemId: user?.paymentProvider,
          amount: Math.round(totalAmount + processsingServiceCharge),
        })
        .then((e) => {
          updateUser({ paymentProvider: e.payItemId });
          addContractNumber(user?.billService?.serviceNumber);
          setisInitiateCashout(true);
          fetchApi("collectstd")
            .post({
              quoteId: e.quoteId,
              customerPhonenumber: user?.customerNumber,
              customerEmailaddress: "cedricngoumti@gmail.com",
              customerName: "WebPAY",
              customerAddress: "WebPAY",
              serviceNumber: user?.customerNumber,
              trid: uuidv4(),
            })
            .then((data) => {
              if (data.ptn) {
                updateUser({ tag: `${data.ptn} - ${user?.customerNumber}` });
                if (isEmbeddedApp) {
                  navigate(`/embed/verify/${data.trid}`);
                } else {
                  navigate(`/verify/${data.trid}`);
                }
              }
            })
            .finally(() => setisInitiateCashout(false));
        })
        .finally(() => setisLoading(false));
    }

    return;
  };

  const handleGoBack = () => {
    if (step === 2) {
      navigate(`/service/${user?.billService?.serviceid}`);
      setStep(1);
    }

    if (step === 3) {
      setStep(2);
    }

    return;
  };

  return (
    <Container bordered={!isSmall} sx={{ position: "relative" }}>
      <Backdrop
        sx={{
          color: "#0D109F",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading || isInitiateCashout}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {step === 1 && (
        <StepFormOne
          step={step}
          service={service}
          handleNext={handleNext}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      )}
      {step === 2 && (
        <StepFormTwo
          service={service}
          handleGoBack={handleGoBack}
          handleNext={handleNext}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          handleReset={handleReset}
          paymentMode={paymentMode}
          setisLoading={setisLoading}
          isLoading={isLoading}
          setPaymentMode={setPaymentMode}
        />
      )}

      {step === 3 && (
        <StepFormThree service={service} selectedPackage={selectedPackage} />
      )}

      {step !== 1 && step !== 2 && (
        <StepperNavigation
          handleReset={handleReset}
          handleGoBack={handleGoBack}
          handleNext={handleNext}
          step={step}
        />
      )}
    </Container>
  );
};

export default StepperBill;
