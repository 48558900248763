import { Typography } from "@mui/material";
import formatCurrency from "../../../utils/formatCurrency";
import useUser from "../../../hooks/useUser";
import convertTimestampToFormattedDate from "../../../utils/convertTimestampToFormattedDate";
import { getProviderByPaymentProvider } from "../../../utils/getInstructionByPaymentProvider";

interface ReceiptProps {
  totalAmount: number;
  data: any;
}

const Receipt = ({ totalAmount, data }: ReceiptProps) => {
  const { user, billSearch } = useUser();

  const formattedTimestamp = convertTimestampToFormattedDate(data?.timestamp);
  const provider = getProviderByPaymentProvider(user?.paymentProvider);

  return (
    <div style={{ flex: 1 }}>
      <img
        src={
          user?.serviceTitle === "Canal+"
            ? "/assets/mav1.png"
            : "/assets/mav2.png"
        }
        alt="Logo"
        style={{
          width: "15%",
          marginLeft: 50,
          marginTop: 50,
        }}
      />
      <div style={{ paddingBottom: 30 }}>
        <h2
          style={{
            color: "#0D109F",
            fontSize: 56,
            lineHeight: "22px",
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Payment Receipt
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          paddingBottom: 30,
        }}
      >
        <div style={{ width: "40%", borderWidth: 1, borderColor: "#DADDE4" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                PTN
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                PTN
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {data?.ptn}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Service:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Service:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {user?.billService?.merchant || user?.serviceTitle}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                No du service:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Service number:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {user?.cardNumber || user?.billService?.billNumber || "N/A"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Reference du Client:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Customer Reference:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {user?.reference || "N/A"}
            </Typography>
          </div>
        </div>
        <div style={{ width: "40%", borderWidth: 1, borderColor: "#DADDE4" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Méthode de Paiement:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Payment Method:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {provider?.name[0].text}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Numero de télephone:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Phone Number:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {user?.customerNumber}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Numero de Notification:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Notification Number:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {user?.customerPhoneNumber}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingTop: 15,
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: "400", fontSize: 25, lineHeight: "17px" }}
              >
                Date de paiement:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 22,
                  lineHeight: "44px",
                  color: "#707070",
                }}
              >
                Payment Date:
              </Typography>
            </div>
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 400,
                lineHeight: "44px",
                alignSelf: "right",
              }}
            >
              {formattedTimestamp}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#F3F3F3",
            color: "#8D8D8D",
            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
            borderWidth: 10,
          }}
        >
          <span style={{ width: "30%", textAlign: "left" }}>Product Name</span>
          <span style={{ width: "30%", textAlign: "center" }}>Package</span>
          <span style={{ width: "30%", textAlign: "right" }}>Phone Number</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
          }}
        >
          <span style={{ width: "30%", textAlign: "left" }}>
            {user?.billService?.merchant || user?.serviceTitle}
          </span>
          <span style={{ width: "30%", textAlign: "center" }}>
            {user?.selectedPackage?.name}
          </span>
          <span style={{ width: "30%", textAlign: "right" }}>
            {user?.customerNumber}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#F3F3F3",
            color: "#8D8D8D",
            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
          }}
        >
          <span style={{ width: "30%", textAlign: "left" }}>Item</span>
          <span style={{ width: "30%", textAlign: "center" }}>Description</span>
          <span style={{ width: "30%", textAlign: "right" }}>Amount</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",

            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
          }}
        >
          <span style={{ width: "30%", textAlign: "left" }}>1</span>
          <span style={{ width: "30%", textAlign: "center" }}>Amount</span>
          <span style={{ width: "30%", textAlign: "right" }}>
            {formatCurrency(totalAmount)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
          }}
        >
          <span style={{ width: "30%", textAlign: "left" }}>2</span>
          <span style={{ width: "30%", textAlign: "center" }}>Fee</span>
          <span style={{ width: "30%", textAlign: "right" }}>
            {formatCurrency(Number(billSearch?.processsingServiceCharge || 0))}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#F3F3F3",
            color: "#8D8D8D",
            alignContent: "center",
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: 15,
          }}
        >
          <span
            style={{
              width: "30%",
              textAlign: "left",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Total Amount Paid (XAF)
          </span>
          <span style={{ width: "30%", textAlign: "center" }}></span>
          <span
            style={{
              width: "30%",
              textAlign: "right",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {formatCurrency(
              Number(totalAmount) +
                Number(billSearch?.processsingServiceCharge || 0)
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
