import config from "../config";

export const PRODUCT = "PRODUCT";
export const SEARCHABLE_BILL = "SEARCHABLE_BILL";
export const TOPUP = "TOPUP";
export const REPORT_LINK = config.REACT_APP_REPORT_LINK;
export const MAX_RECENT_CONTRACTNUMBER_USED = 3;

export const SUPPORTED_SERVICES = [
  Number(config.REACT_APP_ENEO_SERVICE_ID),
  Number(config.REACT_APP_DSTV_SERVICE_ID),
  Number(config.REACT_APP_CAMWATER_SERVICE_ID),
  Number(config.REACT_APP_DOUANES_SERVICE_ID),
  Number(config.REACT_APP_DGI_SERVICE_ID),
  Number(config.REACT_APP_STARTIMES_SERVICE_ID),
  Number(config.REACT_APP_CAMTEL_DATA_SERVICE_ID),
  Number(config.REACT_APP_CANAL_PLUS_SERVICE_ID),
  0,
];

export const TRANSACTION_STATUS = {
  success: "SUCCESS",
  pending: "PENDING",
  errored: "ERRORED",
};

export const PRODUCTDATA = {
  queryFields: [],
  submitFields: [
    {
      fieldId: "SVCNMB",
      label: [
        {
          lang: "en",
          value: null,
        },
        {
          lang: "fr",
          value: null,
        },
      ],
      hint: [
        {
          lang: "en",
          value: null,
        },
        {
          lang: "fr",
          value: null,
        },
      ],
      regex: "^((239|136|238|137|140|540|610|236|440|240|241|242)[0-9]{11})$",
      input: null,
      mandatory: true,
    },
    {
      fieldId: "PAYREF",
      label: [
        {
          lang: "en",
          value: "Payment Reference",
        },
        {
          lang: "fr",
          value: "Payment Reference",
        },
      ],
      hint: [
        {
          lang: "en",
          value: "Payment Reference",
        },
        {
          lang: "fr",
          value: "Payment Reference",
        },
      ],
      input: "ALPHA_CI",
      mandatory: false,
    },
    {
      fieldId: "PHONENMB",
      label: [
        {
          lang: "en",
          value: "Phonenumber",
        },
        {
          lang: "fr",
          value: "Phonenumber",
        },
      ],
      hint: [
        {
          lang: "en",
          value:
            "Enter Phonenumber to receive an SMS when the payment was successfully processed",
        },
        {
          lang: "fr",
          value:
            "Enter Phonenumber to receive an SMS when the payment was successfully processed",
        },
      ],
      input: "ALPHA_CI",
      mandatory: false,
    },
    {
      fieldId: "EMAIL",
      label: [
        {
          lang: "en",
          value: "Email",
        },
        {
          lang: "fr",
          value: "Email",
        },
      ],
      hint: [
        {
          lang: "en",
          value:
            "Enter a receiver address to receive an Email when the payment was successfully processed",
        },
        {
          lang: "fr",
          value:
            "Enter a receiver address to receive an Email when the payment was successfully processed",
        },
      ],
      regex: "/^\\S+@\\S+\\.\\S+$/",
      input: "ALPHA_CI",
      mandatory: false,
    },
  ],
};
