import { Box } from "@mui/material";
import Container from "../../components/layout/container";
import SearchBar from "../../components/ui/searchBar";
import { useState, useEffect } from "react";
import { Service } from "../../models/Service";
import ServiceCard from "../../components/ui/serviceCard";
import { SUPPORTED_SERVICES } from "../../constants/constant";
import useDimensions from "../../hooks/useDimensions";
import getGIMACPaymentMethod from "../../utils/getGIMACPaymentMethod";
import { useQueryCashout, useQueryService } from "../../api/queries";
import ServiceListTitle from "../../components/ui/title/serviceListTitle";
import { useTranslation } from "react-i18next";

const ServiceList = ({
  categoryName,
}: {
  categoryName?: string | undefined;
}) => {
  const [services, setServices] = useState<Service[]>([]);
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [searchText, setSearchText] = useState(""); // Texte de recherche
  const { isSmall } = useDimensions();
  const { data = [] } = useQueryService();
  const {t} = useTranslation()
  useQueryCashout();

  const getAvailableServices = data
    ?.filter((service: Service) => {
      if (categoryName) {
        return (
          SUPPORTED_SERVICES.includes(Number(service?.serviceid)) &&
          service.category === categoryName
        );
      }
      return SUPPORTED_SERVICES.includes(Number(service?.serviceid));
    })
    .sort((a, b) => {
      const idA = SUPPORTED_SERVICES.indexOf(Number(a.serviceid));
      const idB = SUPPORTED_SERVICES.indexOf(Number(b.serviceid));
      return idA - idB;
    });

  useEffect(() => {
    setServices(getAvailableServices);
  }, [data, categoryName]);

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value.toLowerCase(); // Texte de recherche en minuscules
    setSearchText(searchText);

    const filtered = services.filter((service) =>
      service.title.toLowerCase().includes(searchText)
    );

    setFilteredServices(filtered);
  };

  return (
    <Container bordered justifyContent="center" alignItems="center">
      <Box mt={5}>
        <ServiceListTitle>
          {t("service_list.title")}
        </ServiceListTitle>
      </Box>

      <Box mx={isSmall ? 1 : 25}>
        <SearchBar
          placeHolder={t("service_list.find_a_biller")}
          display={"flex"}
          onChange={handleSearch}
          focused
        />
      </Box>
      <Box
        display={isSmall ? "block" : "flex"}
        justifyContent={"flex-start"}
        flexWrap={"wrap"}
        mx={isSmall ? 0 : 10}
        width={"90%"}
        mt={5}
        data-testid="service-grid"
      >
        {searchText && filteredServices.length > 0 && (
          <>
            {filteredServices.map((service, key) => (
              <ServiceCard service={service} key={key} />
            ))}
          </>
        )}

        {!searchText && (
          <>
            {services.map((service, key) => (
              <ServiceCard service={service} key={key} />
            ))}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ServiceList;
