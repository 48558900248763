import config from "../config";

const serviceLogos = {
  [config.REACT_APP_CANAL_PLUS_SERVICE_ID]: "CanalPlus.png",
  [config.REACT_APP_ENEO_SERVICE_ID]: "eneo.png",
  [config.REACT_APP_CAMWATER_SERVICE_ID]: "camwater.png",
  [config.REACT_APP_DOUANES_SERVICE_ID]: "douanes.jpeg",
  [config.REACT_APP_STARTIMES_SERVICE_ID]: "startimes.png",
  [config.REACT_APP_DSTV_SERVICE_ID]: "dstv.png",
  [config.REACT_APP_DGI_SERVICE_ID]: "dgi.png",
  [config.REACT_APP_CAMTEL_DATA_SERVICE_ID]: "blue.png",
};

const getLogoByService = (val: number | string): string => {
  return serviceLogos[`${val}`] || "CanalPlus.png";
};

export default getLogoByService;
